.sectionContainer{
    display: flex;
    width: var(--container-width-100);
    padding: 1rem 0;
}

.mainwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    width: var(--container-width-md);
    margin-inline: auto;
}

.najHeroSection{
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
}



.aboutnaj{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    gap: 2rem;
    left: 0;
    bottom: .2rem;
    /*transform: translate(-50%, -50%);*/
    color: var(--white);
    padding: 1.2rem;
    background-color: rgba(23, 5, 186, 0.80);
    
}

.cover{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    
}
.aboutnaj h2{
    font-size: 3rem;
}

.aboutnaj p{
    font-style: italic;
    font-weight: 600;
}
.najHeroSection img{
    width: fit-content;
    background-size: cover;
}

/*about naj section*/

.aboutNajmain{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.aboutnajContent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.about{
    background-color: var(--color-primary);
    padding: 3rem 2rem;
    justify-content: center;
}

.aboutSubtitle{
    font-size: 2rem;
    font-weight: 800;
    line-height: 2rem;
}

.about p{
    margin-top: .8rem;
    text-align: justify;
    line-height: 1.3rem;
    color: var(--white);
}

/*About offering*/

.aboutOffering {
   display: flex;
   flex-direction: column;
}

.offering{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
    gap: 2rem;

}

.offerTitle{
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.offer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.icon{
    background-color: var(--color-primary-accent);
    padding: .5rem;
    border-radius: .5rem;
}

.landicon{
    font-size: 3rem;
    color: var(--white);
    border: 0;
    padding: 0;
}

.land{
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
}

.naira{
    font-size: 2rem;
}

.price{
   display: flex; 
   font-size: 1.4rem;
   font-weight: 800;
}

/* location, title and neighborhood*/

.location{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: .7rem;
    margin-top: 1rem;
}

.location p {
    font-size: 1rem;
}

.location p span{
    font-weight: 800;
}

.localicon{
    justify-content: center;
    align-self:center;
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    margin-top: .5rem;
    color: var(--white);
}

/*Document*/

.document{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: .7rem;
    margin-top: .5rem;
}

.document p {
    font-size: 1rem;
}

.document p span{
    font-weight: 800;
}

.docuicon{
    justify-content: center;
    align-self:center;
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
    margin-top: .5rem;
    color: var(--white);
}
/*Neighborhood*/

.neighborhood{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: .7rem;
    margin-top: .5rem;
}

.neighborhood p {
    font-size: 1rem;
}

.neighborhood p span{
    font-weight: 800;
}

.neighIcon{
    justify-content: center;
    align-self:center;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    margin-top: .5rem;
    color: var(--white);
}


/*infrastructure section start here*/

.infrastructure{
    width: var(--container-width-100);
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}

.infrasContent{
    width: var(--container-width-100);
    display: flex;
    margin-inline: auto;
    justify-content: center;
    flex-direction: column;
}

.sectionsubTitle{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}

.amenities{
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.amenity{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: .5rem;
    transition: all .2s ease-in-out;
}

.amenity:hover{
    background-color: var(--color-primary);
    padding: 1rem;
    transition: all .2s ease-in-out;
}

.amenity:hover .amenityIcon,
.amenity:hover h5{
    color: var(--white);
    transition: all .2s ease-in-out;
}

.amenityIcon{
    font-size: 3rem;
}

/*infrastructure section ends here*/

/*Development section starts here*/
.development{
    width: var(--container-width-100);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.developmentContent{
    display: flex;
    flex-direction: column;
    width: var(--container-width-100);
    margin-inline: auto;
    justify-content: center;
}

.devPics{
    width: var(--container-width-100);
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
}

.devpic{
    width: 23%;
}
/*Development section ends here*/
.downloads{
    display: flex;
    width: var(--container-width-100);
    justify-content: center;
}

.downloadContents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--container-width-100);
}

.download{
    display: flex;
    width: var(--container-width-100);
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
}

.download button {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--color-primary);
    color: var(--white);
    outline: none;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all .5s ease;
}

.download button:hover{
    background-color: var(--white);
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    transition: all .5s ease;
}
/*downloads section starts here*/


/*media queries for hero section mobile devices*/

@media screen and (min-width: 320px) and (max-width: 760px) {
   .sectionContainer{
    width: var(--container-width-100);
   }

   .mainwrapper{
    width: var(--container-width-lg);
   }

   .najHeroSection{
    position: relative;
    width: var(--container-width-100);
    height: 12rem;
   }

   .aboutnaj{
    position: absolute;
    flex-direction: column;
    width: var(--container-width-100);
    bottom: .2rem;
    left: 0;
    gap: .2rem;
    padding: .8rem;
   }

   .aboutnaj h2 {
    font-size: 1.5rem;
    text-align: center;
   }

   .aboutnaj p {
    text-align: center;
   }

   /* media query for main content*/

   .aboutnajContent {
    grid-template-columns: 1fr;
   }

   .about{
    padding: 1.5rem 1rem;
   }

   .aboutSubtitle {
    text-align: center;
    font-size: 1.5rem;
   }

   .about p{
    text-align: justify;
    word-spacing: 1px;
    line-height: 1.5;
    hyphens: auto;
    text-align-last: left;
    letter-spacing: 0.5px;
   }
   
   .location {
    display: inline-flex;
   }

   .document{
    display: inline-flex;
   }

   .neighborhood {
    display: inline-flex;
   }

   .location p {
    letter-spacing: 0.5px;
    text-align: left;
    font-size: .8rem;
    margin-top: 0.5rem;
   }

   .document p {
    letter-spacing: 0.5px;
    text-align: left;
    font-size: .8rem;
   }
   .neighborhood p {
    letter-spacing: 0.5px;
    text-align: left;
    font-size: .8rem;
   }

   .aboutOffering {
    background-color: var(--color-primary-variant);
    display: flex;
    padding: 1rem;
    color: var(--white);
    justify-content: center;
   }

   .offering {
    padding: .8rem;
   }

   .land{
    color: var(--color-primary-accent);
   }

   .deliverydeposit small {
    color: var(--color-primary-accent);
   }

   /*Amenties*/
   .amenities {
    flex-direction: column;
    gap: 1rem;
   }

   .amenity{
   /* color: var(--color-primary-accent);*/
    background-color: var(--gray);
   }

   /*Development section*/

   .devPics {
    flex-direction: column;
    align-items: center;
   }

   .devpic {
    width: var(--container-width-lg);
    justify-content: center;
   }

   /*Download Section*/

   .download{
    flex-direction: column;
   }

}


/*Media queries for tablets*/

@media screen  and (min-width: 761px) and (max-width: 1023px){
    .mainwrapper{
        width: var(--container-width-lg);
    }

    .najHeroSection {
        position: relative;
        width: var(--container-width-100);
        height: 18rem;
    }
    
    .aboutnaj {
        background-color: var(--color-primary);
        flex-direction: column;
        gap: .5rem;
    }

    /*Main Content */

    .aboutnajContent{
        grid-template-columns: 1fr;
    }

    .about {
        padding: 3.5rem 2.5rem;
    }

    .about p{
        text-align: justify;
        font-size: 1.1rem;
        word-spacing: 1px;
        line-height: 1.6;
        hyphens: auto;
        text-align-last: left;
        letter-spacing: 0.5px;
    }

    .aboutOffering {
        background-color: var(--color-primary-variant);
        color: var(--white);
        padding: 3rem 2rem;
    }

    .offering {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
        margin-top: 3rem;
    }

    .offer{
       display: inline-flex; 
       justify-content: left;
    }

    .land{
        color: var(--color-primary-accent);
    }

    .deliverydeposit small {
        color: var(--color-primary-accent);
    }

     /*Amenties*/
   .amenities {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
   }

   .amenity{
   /* color: var(--color-primary-accent);*/
    background-color: var(--gray);
   }

   .amenity:last-child {
    grid-column: 2 / 3; /* Adjust the grid-column values based on your grid structure */
    justify-self: center;
    width: 100%;
  }

    /*Development section*/

    .developmentContent {
        justify-content: center;
        width: var(--container-width-lg);
        align-content: center;
    }

    .devPics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .devpic {
        width: var(--container-width-100);
    }
    
}