.product__wrapper{
    display: flex;
    justify-content: center;
    width: var(--container-width-md);
    margin-inline: auto;
}

.product__content{
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.productItems{
    width: var(--container-width-100);
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.productItem{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--white);
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.productItemBottom{
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

.estate__name{
    font-size: 1.5rem;
    color: var(--color-primary);
    font-weight: 800;
}

.estateLocation{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: .5rem;
    padding: .5rem 0;
}

.estateLocation .estateIcon{
    font-size: 2rem !important;
    color: var(--color-secondary);
    align-self: flex-start;
}

.estateAddress{
    font-size: 1rem;
}

.estateDocument{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: .5rem;
    padding: .5rem 0;
    font-size: 1.1rem;
    font-weight: 800;
}

.estateDocument .estateIcon{
    font-size: 2rem !important;
    border: none;
    padding: 0;
    color: var(--color-secondary);
}

.estatePrice{
    display: flex;
    justify-content: left;
    gap: 1rem;
    align-items: center;
}

.price{
    display: flex;
    justify-content: left;
    align-items: center;
}

.currencyIcon{
    font-size: 2rem !important;
    color: var(--color-secondary);
}

.plotSize{
    background-color: var(--color-primary);
    padding: .5rem;
    margin: .5rem;
    color: var(--white);
    font-size: .7rem;
    font-weight: 600;
    border-radius: .2rem;
}

.product-btn{
    padding: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: var(--white);
    border-radius: .3rem;
    transition: var(--transition);
}

.prodBtn{
    text-align: center;
    text-decoration: none;
    color: var(--white);
    font-weight: 600;
    font-size: 1.1rem;
}

.product-btn:hover{
    background-color: var(--color-secondary);
    transition: var(--transition);
}

/*Media Queries for small devices*/

@media screen and (min-width: 768px) and (max-width: 1023px){
    .product__wrapper{
        width: var(--container-width-lg);
    }
}