/* ContactForm.css */
.main{
    max-width: 100%;
}
.main__section{
    width: 100%;
}

.section__wrapper{
    width: var(--container-width-md);
    margin-inline: auto;
    padding: 2rem 0;
}

.inspection-section__title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inspection-section__title h2{
    font-size: 3rem;
    text-transform: uppercase;
    color: var(--color-primary);
}

.inspection-section__title p{
    padding: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
}

form {
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem 0;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: var(--text-gray);
  }
  
  input,
  textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #C6C6C6;
  }

  input:focus, textarea:focus {
    border: 1px solid #C6C6C6;
    outline: none;
  }
  
  button {
    width: 100%;
    background-color: var(--color-primary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
button:hover {
    background-color: var(--color-primary-variant);
}

.email__success{
    text-align: center;
    font-weight: bold;
    font-style: italic;
}


@media screen and (max-width: 767px) {
    .inspection-section__title h2{
        font-size: 1.5rem;
    }

    .inspection-section__title p{
       font-size: .7rem; 
       padding: .2rem;
    }
}