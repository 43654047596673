/* Reset and basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Inter', sans-serif;
  }
  
  /* Container for the navigation menu */
 /* .nav-container {
    width: var(--container-width-lg);
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 0;
  }
/*
  
  /* Main navigation container */
  .main__nav-con {
    background-color: var(--white);
    width: 100%;
    box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.1);
  }
  
  /* Logo styling */
  .nav__logo {
    width: 7rem;
  }
  
  /* Menu toggle button (for mobile) */
  .nav__toggle-btn {
    display: none;
  }
  
  /* Navigation links */
  .nav__links {
    display: flex;
    gap: 2rem;
    list-style: none;
  }
  
  .nav__links a {
    font-weight: 700;
    color: var(--color-primary);
    text-decoration: none;
  }
  
  /* Active navigation link styling */
  .active-nav {
    color: red;
  }
  
  /* Styling for the "inspection" button */
  
  
  /* Media Queries for responsive design */
  @media screen and (max-width: 1024px) {
    .nav__toggle-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 3rem;
      height: 3rem;
      font-size: 1.5rem;
      /*background: transparent;*/
      cursor: pointer;
    }
  
  
    .nav__links {
      position: absolute;
      top: 5rem;
      right: 0;
      flex-direction: column;
      gap: 0;
    }
  
    .nav__links li {
      height: 4rem;
      width: 100%;
      box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
    }
  
    .nav__links li a {
      background-color: var(--color-primary);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 5rem 1rem 3rem;
      color: var(--white);
      text-align: center;
    }
  
    /* Additional responsive styling as needed */
    .nav-logo {
      width: 5rem !important;
    }
  
    .nav-logo img {
      width: 5rem;
    }
  
    .show__nav {
      display: flex;
    }
  
    .hide__nav {
      display: none;
    }
  }
  

  img {
    max-width: 100%;
    height: auto;
  }